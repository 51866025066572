
import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"
import { useRef, useState } from "react"
import { Loader2 } from "lucide-react"
import { uploadImage, deleteImage } from "./uploadFile"
import { useToast } from "@/components/ui/use-toast"
import { Toaster } from "@/components/ui/toaster"

export default function App() {

  const showErrorToast = useErrorToast()
  const fileInputRef = useRef()

  const [showResult, setShowResult] = useState(false)


  const [imageUrl, setImageUrl] = useState('')


  const [isUploadingimage, setIsUploadingImage] = useState(false);


  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const [result, setResult] = useState()




  async function handleFileChange(event) {
    const file = event.target.files[0];
    if (!file) return

    setShowResult(false)
    // setResult({})


    setIsUploadingImage(true);

    const [imageUrlResult, error] = await uploadImage(file)

    if (error) {
      showErrorToast(error.message)
      return
    }

    console.log(imageUrlResult)
    setImageUrl(imageUrlResult)
    setIsUploadingImage(false);
  }

  async function analysis() {

    setShowResult(true)

    setIsAnalyzing(true)


    const response = await fetch('https://skindisasecheck-agsdivnvtc.cn-shanghai.fcapp.run', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ imageUrl: imageUrl }) // 如果需要发送数据
    });

    const data = await response.json();


    console.log("result1:" + JSON.stringify(data))

    setResult(data)

    setIsAnalyzing(false)

    deleteImage(imageUrl)

  }




  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Toaster />
      <header className="bg-[#d0e8f2] py-4 px-6">
        <div className="flex items-center">
          <CrossIcon className="h-6 w-6 mr-2" />
          <h1 className="text-lg font-medium">SkinCheck AI</h1>
        </div>
      </header>
      <main className="flex flex-none flex-col justify-center items-center md:flex-row gap-2 p-2 ">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
          ref={fileInputRef}
        />
        <div className="flex flex-col flex-none  md:items-end justify-center mr-4 gap-2">
          <div className="border-2 border-dashed border-[#93a8b1] rounded-lg p-4 w-[300px]  md:w-[500px] flex flex-col items-center justify-center"
            onClick={() => fileInputRef.current.click()}
          >
            <CloudUploadIcon className="h-6 w-6 mb-2 text-[#a4d5eb]" />
            <p className="text-sm ">Tap to upload your skin image</p>
          </div>

          <div className="bg-[#d0e8f2] rounded-lg p-4 mb-4 w-[300px] md:w-[500px] aspect-square">
            <img src={imageUrl} className={imageUrl ? "" : "hidden"} ></img>
            <ImagePlaceholder className={(!imageUrl ? "" : "hidden") + " object-cover w-full h-full opacity-10"}  ></ImagePlaceholder>
          </div>


          <Button className="w-[300px] md:w-[500px]" onClick={analysis} disabled={!imageUrl || result}>Analyze</Button>
        </div>
        {

          showResult &&
          (isAnalyzing ? <Spin /> :
            (<div className="flex flex-col flex-none items-center justify-center">

              {isUploadingimage ? <Spin />
                : (<>
                  <h2 className="text-lg font-medium mb-4 mr-4">Analysis Results</h2>
                  <div className="flex flex-col w-[300px] md:w-[500px] gap-2">
                    {Object.entries(result.resultsEnglish)
                      .sort(([, a], [, b]) => b - a)
                      .map(([disease, probability], index) => (

                        <Card key={disease} className={index !== 0 ? "opacity-30" : ""}>
                          <CardHeader>
                            <CardTitle>{disease.replace('_', ' ')}</CardTitle>
                          </CardHeader>
                          <CardContent>
                            <div className="mt-2 text-sm text-muted-foreground">Probability: {(probability * 100).toFixed(2)}%</div>
                            {/* <p className="text-sm">Melanoma is a type of skin cancer that can be serious if not treated early.</p> */}
                          </CardContent>
                        </Card>

                      ))}
                  </div>
                </>)}


            </div>))
        }
      </main>
      <footer className="bg-[#d0e8f2] py-4 px-6 text-sm text-[#666] text-center">
        This tool is for informational purposes only
      </footer>
    </div>
  )
}

function CloudUploadIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
      <path d="M12 12v9" />
      <path d="m16 16-4-4-4 4" />
    </svg>
  )
}


function CrossIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11 2a2 2 0 0 0-2 2v5H4a2 2 0 0 0-2 2v2c0 1.1.9 2 2 2h5v5c0 1.1.9 2 2 2h2a2 2 0 0 0 2-2v-5h5a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-5V4a2 2 0 0 0-2-2h-2z" />
    </svg>
  )
}

function ImagePlaceholder(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
      <circle cx="9" cy="9" r="2" />
      <path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21" />
    </svg>
  )
}


function useErrorToast() {
  const { toast } = useToast()

  const showErrorToast = (message) => {
    toast({
      title: "Error",
      description: message,
      variant: "destructive",
    })
  }

  return showErrorToast
}

function Spin() {
  return <div className="flex items-center justify-center w-[300px] md:w-[500px]">
    <Loader2 className="h-32 w-32 animate-spin" />
  </div>
}