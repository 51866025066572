const OSS = require('ali-oss');

async function initClient() {

    const response = await fetch('https://sts-kdcepcfjfz.cn-shanghai.fcapp.run')

    if (!response.ok) {
        return null
    }

    const stsRes = await response.json()

    console.log("sts" + JSON.stringify(stsRes))

    const options = {
        accessKeyId: stsRes.credentials.accessKeyId,
        accessKeySecret: stsRes.credentials.accessKeySecret,
        stsToken: stsRes.credentials.securityToken,
        bucket: 'skin-disease-check',
        region: 'oss-cn-shanghai',
        // endpoint: 'oss-accelerate-overseas.aliyuncs.com'
    }
    console.log("options: " + JSON.stringify(options))

    return new OSS(options);
}



const client = await initClient()

export async function deleteImage(url) {
    if (!url) return
    client.delete(url.split('/').at(-1))
}

export async function uploadImage(file) {
    if (!file) return;

    try {
        // 生成一个唯一的文件名
        const fileName = Date.now() + '_' + file.name

        // 根据文件类型设置正确的MIME类型
        let contentType;
        if (file.type === 'image/png') {
            contentType = 'image/png';
        } else if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
            contentType = 'image/jpeg';
        } else {

            return [null, new Error('Unsupported image type. Only PNG and JPEG are allowed.')]

        }

        const options = {
            headers: {
                'Content-Type': contentType,
            }
        };

        //http://<您的Bucket名称>.<Endpoint>/<文件名>
        const result = await client.put(fileName, file, options);

        if (result.res.statusCode === 200) {
            return [result.res.requestUrls[0], null]
        }


        return [null, new Error("upload image failed")]

    } catch (e) {
        console.log(e)
        return [null, new Error("upload image failed")]
    }
}